import httpClient from './http-client';

export interface User {
    id: number;
    companyId: number;
    role: 'editor' | 'admin';
    email: string;
    isDeleted: boolean;
}

export interface Response {
    result: User[];
}

const getUsers = async (): Promise<Response> => {
    const { data } = await httpClient.post<Response>('/api/get-users');
    return data;
};

export default getUsers;

import React, { useEffect, useState } from 'react';
import Loader from 'components/Loader';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import getAnalytics, { AnalyticsResponse } from 'api/getAnalytics';
import { BarChart, LineChart, PieChart } from '@mui/x-charts';
import { NORMAL_AI_STATUS } from 'api/getContacts';
import SequenceFilter from './Analytics/SequenceFilter';

function Analytics() {
    const [currentSequenceId, setCurrentSequenceId] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [dateRange, setDateRange] = useState<{ start: Dayjs | null; end: Dayjs | null }>({
        start: dayjs().utc().subtract(30, 'day').startOf('day'),
        end: dayjs().utc().endOf('day')
    });
    const [dateRangeError, setDateRangeError] = useState<string | null>(null);
    const [analytics, setAnalytics] = useState<AnalyticsResponse>({
        totalContacts: 0,
        statusBreakdown: [],
        newContactsCount: 0,
        dailyEngagement: [],
        dailyCallEngagement: [],
        dailySmsEngagement: [],
        engagementMetrics: {
            avg: 0,
            std: 0
        },
        callEngagementMetrics: {
            avg: 0,
            std: 0
        },
        smsEngagementMetrics: {
            avg: 0,
            std: 0
        },
        callMetrics: {
            total: 0,
            answered: 0
        },
        smsMetrics: {
            total: 0,
            userSms: 0,
            assistantSms: 0,
            undeliveredAndFailedSms: 0
        }
    });

    const getDateRangeError = (start: Dayjs | null, end: Dayjs | null): string | null => {
        if (!start || !end) return null;

        if (end.isBefore(start)) {
            return 'End date must be after start date';
        }

        const monthsDiff = end.diff(start, 'month', true);
        if (monthsDiff > 3) {
            return 'Date range cannot exceed 3 months';
        }

        return null;
    };
    useEffect(() => {
        const error = getDateRangeError(dateRange.start, dateRange.end);
        if (error) {
            setDateRangeError(error);
        } else {
            setDateRangeError(null);
            const fetchAnalytics = async () => {
                setIsLoading(true);
                if (!currentSequenceId || !dateRange.start || !dateRange.end) {
                    setIsLoading(false);
                    return;
                }
                try {
                    const data = await getAnalytics({
                        sequenceId: currentSequenceId,
                        start: dateRange.start?.utc().startOf('day').valueOf() ?? null,
                        end: dateRange.end?.utc().endOf('day').valueOf() ?? null
                    });
                    setAnalytics(prev => ({
                        ...prev,
                        ...data
                    }));
                    setIsLoading(false);
                } catch (error) {
                    console.error('Error fetching analytics:', error);
                    setIsLoading(false);
                }
            };

            fetchAnalytics();
        }
    }, [currentSequenceId, dateRange.start, dateRange.end]);

    if (isLoading) {
        return <Loader />;
    }

    const sortedStatusBreakdown = [...analytics.statusBreakdown]
        .sort((a, b) => b.count - a.count)
        .map(item => ({
            status: item.status ?? NORMAL_AI_STATUS,
            count: item.count
        }));

    const dailyEngagementData = (() => {
        const error = getDateRangeError(dateRange.start, dateRange.end);
        if (error) return [];

        const dates: string[] = [];
        const start = dateRange.start?.startOf('day');
        const end = dateRange.end?.endOf('day');

        if (start && end) {
            let current = start.clone();
            while (current.isBefore(end) || current.isSame(end, 'day')) {
                dates.push(current.format('YYYY-MM-DD'));
                current = current.add(1, 'day');
            }
        }

        // Create lookup map for engagement data
        const engagementMap = new Map(
            analytics.dailyEngagement.map(item => [
                dayjs(item.date).format('YYYY-MM-DD'),
                {
                    engaged: item.engaged,
                    total: item.total,
                    ratio: item.ratio
                }
            ])
        );

        const callEngagementMap = new Map(
            analytics.dailyCallEngagement.map(item => [
                dayjs(item.date).format('YYYY-MM-DD'),
                {
                    engaged: item.engaged,
                    total: item.total,
                    ratio: item.ratio
                }
            ])
        );

        const smsEngagementMap = new Map(
            analytics.dailySmsEngagement.map(item => [
                dayjs(item.date).format('YYYY-MM-DD'),
                {
                    engaged: item.engaged,
                    total: item.total,
                    ratio: item.ratio
                }
            ])
        );
        // Map dates to chart data points with all metrics
        return dates.map(date => {
            const overall = engagementMap.get(date) ?? { engaged: 0, total: 0, ratio: 0 };
            const call = callEngagementMap.get(date) ?? { engaged: 0, total: 0, ratio: 0 };
            const sms = smsEngagementMap.get(date) ?? { engaged: 0, total: 0, ratio: 0 };

            return {
                date,
                engaged: overall.engaged,
                total: overall.total,
                ratio: overall.ratio,
                callEngaged: call.engaged,
                callTotal: call.total,
                callRatio: call.ratio,
                smsEngaged: sms.engaged,
                smsTotal: sms.total,
                smsRatio: sms.ratio
            };
        });
    })();

    return (
        <div className="flex h-full w-full flex-col px-4 sm:px-6 lg:px-8">
            {/* Sequence Selection and Date Range Section */}
            <div className="mb-4">
                <div className="rounded-lg bg-white p-4 shadow">
                    <div className="flex flex-wrap items-center gap-4">
                        <SequenceFilter
                            currentSequenceId={currentSequenceId}
                            setCurrentSequenceId={setCurrentSequenceId}
                        />
                        <div className="rounded-lg bg-gray-100 p-2">
                            <div className="flex items-center gap-2">
                                <h3 className="text-md font-semibold text-gray-800">Total Contacts: </h3>
                                <p className="text-2xl font-bold text-indigo-700">{analytics.totalContacts}</p>
                            </div>
                        </div>
                        {/* Date Range Picker and New Contacts */}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div className="flex flex-col gap-2">
                                <div className="flex gap-4">
                                    <DatePicker
                                        label="Start Date (UTC)"
                                        value={dateRange.start}
                                        onChange={newValue => {
                                            const newStart = newValue?.utc().startOf('day') ?? null;
                                            setDateRange(prev => ({ ...prev, start: newStart }));
                                        }}
                                    />
                                    <DatePicker
                                        label="End Date (UTC)"
                                        value={dateRange.end}
                                        onChange={newValue => {
                                            const newEnd = newValue?.utc().endOf('day') ?? null;
                                            setDateRange(prev => ({ ...prev, end: newEnd }));
                                        }}
                                    />
                                </div>
                            </div>
                        </LocalizationProvider>
                        {dateRangeError && <div className="font-medium text-red-600">{dateRangeError}</div>}
                        <div className="rounded-lg bg-gray-100 p-2">
                            <div className="flex items-center gap-2">
                                <h3 className="text-md font-semibold text-gray-800">
                                    New Contacts (created between dates):{' '}
                                </h3>
                                <p className="text-2xl font-bold text-indigo-700">{analytics.newContactsCount}</p>
                            </div>
                        </div>
                    </div>
                    {/* Charts Section */}
                    <div className="mt-4 grid grid-cols-1 gap-4 lg:grid-cols-2">
                        {analytics.statusBreakdown.length > 0 ? (
                            <>
                                {/* Bar Chart */}
                                <div className="rounded-lg bg-gray-100 p-4">
                                    <div className="mb-2 flex items-center justify-center">
                                        <h3 className="text-lg font-semibold text-gray-800">
                                            Status Breakdown (updated between dates)
                                        </h3>
                                    </div>
                                    <BarChart
                                        dataset={sortedStatusBreakdown}
                                        yAxis={[
                                            {
                                                scaleType: 'band',
                                                dataKey: 'status',
                                                tickLabelStyle: {
                                                    textAnchor: 'end',
                                                    fill: '#1F2937'
                                                }
                                            }
                                        ]}
                                        xAxis={[{ label: 'Count' }]}
                                        series={[{ dataKey: 'count', label: 'Status Count' }]}
                                        layout="horizontal"
                                        height={250}
                                        margin={{ left: 200 }}
                                        barLabel="value"
                                        grid={{ vertical: true }}
                                    />
                                </div>

                                {/* Pie Chart */}
                                <div className="rounded-lg bg-gray-100 p-4">
                                    <div className="mb-2 flex items-center justify-center">
                                        <h3 className="text-lg font-semibold text-gray-800">
                                            Status Breakdown (updated between dates)
                                        </h3>
                                    </div>
                                    <PieChart
                                        series={[
                                            {
                                                data: sortedStatusBreakdown.map(item => ({
                                                    id: item.status,
                                                    value: (item.count / (analytics.newContactsCount + 0.000001)) * 100,
                                                    label: `${item.status} (${item.count})`
                                                })),
                                                arcLabel: item => `${item.id}`,
                                                arcLabelMinAngle: 35,
                                                highlightScope: { faded: 'global', highlighted: 'item' },
                                                valueFormatter: item => `${item.value.toFixed(1)}%`
                                            }
                                        ]}
                                        height={250}
                                        width={600}
                                        slotProps={{
                                            legend: {
                                                labelStyle: {
                                                    fontSize: 10,
                                                    fill: '#1F2937'
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </>
                        ) : (
                            <p className="font-medium text-gray-700">No status data available</p>
                        )}
                    </div>
                </div>
            </div>

            {/* Daily Engagement Chart and Metrics */}
            <div className="flex flex-1 gap-4 overflow-hidden">
                <div className="w-2/3 rounded-lg bg-white p-4 shadow">
                    <div className="mb-2 flex items-center justify-center">
                        <h3 className="mb-2 text-lg font-semibold text-gray-800">
                            Daily Engagement
                            {/* ({' '}
                            <span className="text-[#02B2AF]">
                                Overall - Avg: {(analytics.engagementMetrics.avg * 100).toFixed(1)}%, Std:{' '}
                                {(analytics.engagementMetrics.std * 100).toFixed(1)}%
                            </span>{' '}
                            |{' '}
                            <span className="text-[#2E96FF]">
                                Calls - Avg: {(analytics.callEngagementMetrics.avg * 100).toFixed(1)}%, Std:{' '}
                                {(analytics.callEngagementMetrics.std * 100).toFixed(1)}%
                            </span>{' '}
                            |{' '}
                            <span className="text-[#B800D8]">
                                SMS - Avg: {(analytics.smsEngagementMetrics.avg * 100).toFixed(1)}%, Std:{' '}
                                {(analytics.smsEngagementMetrics.std * 100).toFixed(1)}%
                            </span>{' '}
                            ) */}
                        </h3>
                    </div>
                    <div className="h-[calc(100%-3rem)]">
                        <LineChart
                            dataset={dailyEngagementData}
                            xAxis={[
                                {
                                    dataKey: 'date',
                                    scaleType: 'point',
                                    valueFormatter: value => dayjs(value).format('MM-DD'),
                                    tickLabelStyle: {
                                        angle: 45,
                                        textAnchor: 'start',
                                        fontSize: 12,
                                        fill: '#1F2937'
                                    }
                                }
                            ]}
                            yAxis={[
                                {
                                    valueFormatter: value => `${(value * 100).toFixed(1)}%`,
                                    tickLabelStyle: {
                                        fill: '#1F2937'
                                    }
                                }
                            ]}
                            series={[
                                {
                                    dataKey: 'ratio',
                                    curve: 'linear',
                                    showMark: true,
                                    label: `Overall (Avg: ${(analytics.engagementMetrics.avg * 100).toFixed(1)}%, Std: ${(analytics.engagementMetrics.std * 100).toFixed(1)}%)`,
                                    valueFormatter: (value, index) => {
                                        const total = dailyEngagementData[index.dataIndex]?.total ?? 0;
                                        const engaged = dailyEngagementData[index.dataIndex]?.engaged ?? 0;
                                        return `Total: ${total}, Engaged: ${engaged}, Ratio: ${((value ?? 0) * 100).toFixed(1)}%`;
                                    }
                                },
                                {
                                    dataKey: 'callRatio',
                                    curve: 'linear',
                                    showMark: true,
                                    label: `Calls (Avg: ${(analytics.callEngagementMetrics.avg * 100).toFixed(1)}%, Std: ${(analytics.callEngagementMetrics.std * 100).toFixed(1)}%)`,
                                    valueFormatter: (value, index) => {
                                        const total = dailyEngagementData[index.dataIndex]?.callTotal ?? 0;
                                        const engaged = dailyEngagementData[index.dataIndex]?.callEngaged ?? 0;
                                        return `Total: ${total}, Engaged: ${engaged}, Ratio: ${((value ?? 0) * 100).toFixed(1)}%`;
                                    }
                                },
                                {
                                    dataKey: 'smsRatio',
                                    curve: 'linear',
                                    showMark: true,
                                    label: `SMS (Avg: ${(analytics.smsEngagementMetrics.avg * 100).toFixed(1)}%, Std: ${(analytics.smsEngagementMetrics.std * 100).toFixed(1)}%)`,
                                    valueFormatter: (value, index) => {
                                        const total = dailyEngagementData[index.dataIndex]?.smsTotal ?? 0;
                                        const engaged = dailyEngagementData[index.dataIndex]?.smsEngaged ?? 0;
                                        return `Total: ${total}, Engaged: ${engaged}, Ratio: ${((value ?? 0) * 100).toFixed(1)}%`;
                                    }
                                }
                            ]}
                            height={300}
                            margin={{ right: 300 }}
                            slotProps={{
                                legend: {
                                    position: { vertical: 'top', horizontal: 'right' },
                                    direction: 'column'
                                }
                            }}
                        />
                    </div>
                </div>
                {/* Metrics Cards */}
                <div className="flex w-1/3 flex-col gap-4">
                    {/* Call Metrics Card */}
                    <div className="flex-1 rounded-lg bg-white p-4 shadow">
                        <h3 className="mb-2 text-lg font-semibold text-gray-800">Call Metrics</h3>
                        <div className="space-y-2">
                            <div className="flex justify-between">
                                <span className="text-gray-700">Total Calls:</span>
                                <span className="font-bold text-gray-900">{analytics.callMetrics.total}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="flex items-center text-gray-700">
                                    <span className="mr-2 h-1 w-1 rounded-full bg-gray-500"></span>
                                    Answered:
                                </span>
                                <span className="font-bold text-gray-900">{analytics.callMetrics.answered}</span>
                            </div>
                        </div>
                    </div>

                    {/* SMS Metrics Card */}
                    <div className="flex-1 rounded-lg bg-white p-4 shadow">
                        <h3 className="mb-2 text-lg font-semibold text-gray-800">SMS Metrics</h3>
                        <div className="space-y-2">
                            <div className="flex justify-between">
                                <span className="text-gray-700">Total SMS:</span>
                                <span className="font-bold text-gray-900">{analytics.smsMetrics.total}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="flex items-center text-gray-700">
                                    <span className="mr-2 h-1 w-1 rounded-full bg-gray-500"></span>
                                    User SMS:
                                </span>
                                <span className="font-bold text-gray-900">{analytics.smsMetrics.userSms}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="flex items-center text-gray-700">
                                    <span className="mr-2 h-1 w-1 rounded-full bg-gray-500"></span>
                                    Assistant SMS:
                                </span>
                                <span className="font-bold text-gray-900">{analytics.smsMetrics.assistantSms}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="pl-8 text-gray-700">Failed and Undelivered SMS:</span>
                                <span className="font-bold text-gray-900">
                                    {analytics.smsMetrics.undeliveredAndFailedSms}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Analytics;

import React from 'react';
import updateContact from 'api/updateContact';
import { FormControl, Select, MenuItem, SelectChangeEvent } from '@mui/material';

interface Props {
    data: { id: number; nextStep: string | null };
}

function NextStepSelect({ data }: Props) {
    const [nextStep, setNextStep] = React.useState<string | null>(data.nextStep);

    const handleChange = async (event: SelectChangeEvent<string>) => {
        const newStep = event.target.value === 'null' ? null : event.target.value;
        setNextStep(newStep);
        await updateContact({
            contactId: data.id,
            nextStep: newStep
        });
    };

    return (
        <FormControl size="small">
            <Select
                value={nextStep || ''}
                onChange={handleChange}
                displayEmpty
                sx={{
                    '& .MuiOutlinedInput-notchedOutline': { borderWidth: '1px' },
                    '& .MuiSelect-select': { py: 0.5 } // Reduce padding top and bottom
                }}
            >
                {nextStep === 'schedule-unresponsive' && (
                    <MenuItem value="schedule-unresponsive">set as unresponsive and turn off AI</MenuItem>
                )}
                {nextStep === 'schedule-reset-call' && (
                    <MenuItem value="schedule-reset-call">Reset new info and make a call</MenuItem>
                )}
                {nextStep === 'schedule-reset-sms' && (
                    <MenuItem value="schedule-reset-sms">Reset new info and send an sms</MenuItem>
                )}
                <MenuItem value="schedule-call">Make a call</MenuItem>
                <MenuItem value="schedule-sms">Send an SMS</MenuItem>
            </Select>
        </FormControl>
    );
}

export default NextStepSelect;

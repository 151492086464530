import React, { useEffect, useState } from 'react';
import { Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import Loader from 'components/Loader';
import getSequences, { SequenceArray } from 'api/getSequences';

function SequenceFilter({
    currentSequenceId,
    setCurrentSequenceId
}: {
    currentSequenceId: string | null;
    setCurrentSequenceId: (sequenceId: string) => void;
}) {
    const [sequences, setSequences] = useState<SequenceArray[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchSequences = async () => {
            setIsLoading(true);
            try {
                const fetchedSequences = await getSequences();
                setSequences(fetchedSequences);
                if (currentSequenceId === null) {
                    setCurrentSequenceId(fetchedSequences[0].sequenceId);
                }
            } catch (error) {
                console.error('Error fetching sequences:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchSequences();
    }, []);
    console.log('currentSequenceId', currentSequenceId);
    const handleSequenceChange = (event: SelectChangeEvent) => {
        const sequenceId = event.target.value;
        setCurrentSequenceId(sequenceId);
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <FormControl sx={{ minWidth: 120, height: '2rem' }}>
            <InputLabel id="sequence-select-label" sx={{ lineHeight: '2rem', top: '-6px' }}>
                Sequence
            </InputLabel>
            <Select
                labelId="sequence-select-label"
                id="sequence-select"
                value={currentSequenceId || ''}
                label="Sequence"
                onChange={e => handleSequenceChange(e)}
                sx={{ height: '2rem' }}
            >
                {sequences.map(sequence => (
                    <MenuItem key={sequence.sequenceId} value={sequence.sequenceId}>
                        {sequence.sequenceLabel}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default SequenceFilter;

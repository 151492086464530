import httpClient from './http-client';

export type AgentConfig = {
    id: string;
    condition: {
        name: string;
    };
    call: string | null;
    sms: string[] | null;
    other: string | null;
}[];

const getAgentConfig = async (): Promise<AgentConfig> => {
    const { data } = await httpClient.post<AgentConfig>('/api/get-agent-config');
    return data;
};

export default getAgentConfig;

import httpClient from 'api/http-client';

interface ResetContactRequest {
    contactId: number;
}

const resetContact = async (request: ResetContactRequest): Promise<void> => {
    await httpClient.post('/api/reset-contact', request);
};

export default resetContact;

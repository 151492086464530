import React, { useState } from 'react';
import sendSms from 'api/sendMessage';
import SpinLoader from 'components/ui/spinLoader';
import { pause } from 'lib/pause';

interface SendSmsFormProps {
    contactId: string;
}

const SendSmsForm: React.FC<SendSmsFormProps> = ({ contactId }) => {
    const [message, setMessage] = useState('');
    const [isSendSms, setIsSendSms] = useState(false);

    const sendMessage = async (contactId: string, message: string) => {
        if (message === '') return;
        setIsSendSms(true);
        await pause(2000);
        await sendSms({ contactId: parseInt(contactId), message });
        setIsSendSms(false);
        setMessage('');
    };

    return (
        <div className="h-30 flex flex-col rounded-md border bg-white p-2">
            <textarea
                className="mb-2 w-full flex-grow rounded-md border p-1"
                rows={4}
                value={message}
                onChange={e => setMessage(e.target.value)}
                placeholder="Type your message here..."
            />
            <div className="text-right">
                <button
                    className="h-6 w-32 rounded bg-blue-500 px-2 text-white"
                    type="button"
                    onClick={() => sendMessage(contactId, message)}
                    disabled={isSendSms}
                >
                    <div className="flex justify-center">
                        {isSendSms ? <SpinLoader className="mr-2 h-4 w-4 animate-spin" /> : 'Send Sms'}
                    </div>
                </button>
            </div>
        </div>
    );
};

export default SendSmsForm;

import httpClient from './http-client';

export interface User {
    id: number;
    isDeleted: boolean;
}

export interface Response {
    result: 'success';
}

const updateUser = async (user: User): Promise<Response> => {
    const { data } = await httpClient.post<Response>(`/api/update-user`, user);
    return data;
};

export default updateUser;

import httpClient from './http-client';

export interface AnalyticsResponse {
    totalContacts: number;
    statusBreakdown: { status: string; count: number }[];
    newContactsCount: number;
    dailyEngagement: { date: string; engaged: number; total: number; ratio: number }[];
    dailyCallEngagement: { date: string; engaged: number; total: number; ratio: number }[];
    dailySmsEngagement: { date: string; engaged: number; total: number; ratio: number }[];
    engagementMetrics: {
        avg: number;
        std: number;
    };
    callEngagementMetrics: {
        avg: number;
        std: number;
    };
    smsEngagementMetrics: {
        avg: number;
        std: number;
    };
    callMetrics: {
        total: number;
        answered: number;
    };
    smsMetrics: {
        total: number;
        userSms: number;
        assistantSms: number;
        undeliveredAndFailedSms: number;
    };
}

const getAnalytics = async (payload: {
    sequenceId: string;
    start: number;
    end: number;
}): Promise<AnalyticsResponse> => {
    const { data } = await httpClient.post<AnalyticsResponse>('/api/get-analytics', payload);
    return data;
};

export default getAnalytics;

import React, { useState } from 'react';
import createCallTask from 'api/create-call-task';
import SpinLoader from 'components/ui/spinLoader';
import { pause } from 'lib/pause';

interface CreateAICallButtonProps {
    contactId: string;
    onCall: () => void;
}

const CreateAICallButton: React.FC<CreateAICallButtonProps> = ({ contactId, onCall }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleCreateCall = async () => {
        setIsSubmitting(true);
        await pause(2000);
        await createCallTask({ contactId: parseInt(contactId) });
        setIsSubmitting(false);
        onCall();
    };

    return (
        <button
            className="h-6 w-56 rounded bg-blue-500 px-2 text-white"
            type="button"
            onClick={handleCreateCall}
            disabled={isSubmitting}
        >
            <div className="flex justify-center">
                {isSubmitting ? <SpinLoader className="mr-2 h-4 w-4 animate-spin" /> : 'Make AI outbound call'}
            </div>
        </button>
    );
};

export default CreateAICallButton;

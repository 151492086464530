import React, { useEffect, useState } from 'react';
import getAgentConfig, { AgentConfig } from 'api/get-agent-config';
import Loader from 'components/Loader';

function AgentConfigPage() {
    const [agentConfigs, setAgentConfigs] = useState<AgentConfig>([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchAgentConfigs = async () => {
        setIsLoading(true);
        try {
            const configs = await getAgentConfig();
            console.log('agentConfigs', configs);
            setAgentConfigs(configs);
        } catch (error) {
            console.error('Error fetching agent configs:', error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchAgentConfigs();
    }, []);

    if (isLoading) {
        return (
            <div className="flex h-[100vh] w-[100vw] items-center justify-center">
                <Loader />
            </div>
        );
    }

    return (
        <div className="p-4">
            <h1 className="mb-4 text-2xl font-bold">Agent Configuration</h1>
            <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                            >
                                ID
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                            >
                                Condition
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                            >
                                Call
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                            >
                                SMS
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                            >
                                Other
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                        {agentConfigs.map(config => (
                            <tr key={config.id}>
                                <td className="whitespace-nowrap px-6 py-4">{config.id}</td>
                                <td className="whitespace-nowrap px-6 py-4">{config.condition.name}</td>
                                <td className="whitespace-nowrap px-6 py-4">{config.call ?? '-'}</td>
                                <td className="px-6 py-4">
                                    {config.sms ? (
                                        <div className="flex flex-col gap-1">
                                            {config.sms.map((sms, index) => (
                                                <span key={index}>{sms}</span>
                                            ))}
                                        </div>
                                    ) : (
                                        '-'
                                    )}
                                </td>
                                <td className="whitespace-nowrap px-6 py-4">{config.other ?? '-'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default AgentConfigPage;

import React, { useState } from 'react';
import {
    TextField,
    Button,
    Card,
    CardContent,
    CardActions,
    Typography,
    Box,
    Snackbar,
    Alert,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';
import resetPassword from 'api/reset-password';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { TrashIcon } from '@heroicons/react/24/outline';
import IconButton from '@mui/material/IconButton';
import getUsers, { User } from 'api/get-users';
import updateUser from 'api/update-user';

const SettingsPage = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [users, setUsers] = useState<User[]>([]);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [userToDelete, setUserToDelete] = useState<number | null>(null);

    React.useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const res = await getUsers();
            setUsers(res.result);
        } catch (error) {
            setMessage('Failed to fetch users');
            setOpenSnackbar(true);
        }
    };

    const openDeleteDialog = (userId: number) => {
        setUserToDelete(userId);
        setDeleteDialogOpen(true);
    };

    const handleDeleteUser = async () => {
        if (!userToDelete) return;

        try {
            const res = await updateUser({ id: userToDelete, isDeleted: true });
            if (res.result === 'success') {
                setMessage('User deleted successfully');
                setOpenSnackbar(true);
                fetchUsers(); // Refresh the list
            }
        } catch (error) {
            setMessage('Failed to delete user');
            setOpenSnackbar(true);
        } finally {
            setDeleteDialogOpen(false);
            setUserToDelete(null);
        }
    };

    const columns: GridColDef[] = [
        { field: 'email', headerName: 'Email', flex: 1 },
        { field: 'role', headerName: 'Role', width: 120 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            renderCell: params => (
                <IconButton onClick={() => openDeleteDialog(params.row.id)} color="error">
                    <TrashIcon className="h-5 w-5" />
                </IconButton>
            )
        }
    ];

    const handleSubmit = async (e: React.FormEvent) => {
        // stop refreshing the page when submitting the form
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setMessage('New password and confirm password do not match.');
            setOpenSnackbar(true);
            return;
        }

        if (newPassword.length < 6) {
            setMessage('Password must be at least 6 characters long.');
            setOpenSnackbar(true);
            return;
        }

        try {
            const res: { result: string; message: string } = await resetPassword({ oldPassword, newPassword });
            if (res.result === 'error') {
                setMessage(res.message);
                setOpenSnackbar(true);
            }

            if (res.result === 'success') {
                setMessage(res.message);
                setOpenSnackbar(true);
                setOldPassword('');
                setNewPassword('');
                setConfirmPassword('');
            }
        } catch (error) {
            setMessage('An error occurred. Please try again later.');
            setOpenSnackbar(true);
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <Box sx={{ maxWidth: 800, margin: 'auto', mt: 8 }}>
            <Card>
                <CardContent>
                    <Typography variant="h5" component="div" gutterBottom>
                        Reset Password
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Old Password"
                            type="password"
                            value={oldPassword}
                            onChange={e => setOldPassword(e.target.value)}
                            required
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="New Password"
                            type="password"
                            value={newPassword}
                            onChange={e => setNewPassword(e.target.value)}
                            required
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Confirm New Password"
                            type="password"
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value)}
                            required
                        />
                        <CardActions sx={{ justifyContent: 'flex-end', padding: '16px 0 0' }}>
                            <Button type="submit" variant="contained" color="primary">
                                Reset
                            </Button>
                        </CardActions>
                    </form>
                </CardContent>
            </Card>
            <Card sx={{ mb: 4 }}>
                <CardContent>
                    <Typography variant="h5" component="div" gutterBottom>
                        Manage Users
                    </Typography>
                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={users.filter(user => !user.isDeleted)}
                            columns={columns}
                            disableRowSelectionOnClick
                        />
                    </Box>
                </CardContent>
            </Card>
            <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this user?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleDeleteUser} color="error" variant="contained">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={message.includes('successfully') ? 'success' : 'error'}
                    sx={{ width: '100%' }}
                    elevation={6}
                    variant="filled"
                >
                    {message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default SettingsPage;

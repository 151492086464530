import React, { useEffect, useState } from 'react';
import Loader from 'components/Loader';
import { Link } from 'react-router-dom';
import getTransferLines from 'api/getTransferLines';
import { TransferHistory } from 'types/transferHistory';

function Transfers() {
    const [transferLineHistory, setTransferLineHistory] = useState<TransferHistory[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchTransferLines = async () => {
        setIsLoading(true);
        const res = await getTransferLines();
        setTransferLineHistory(res.transferLineHistory);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchTransferLines();
        const interval = setInterval(async () => {
            const res = await getTransferLines();
            setTransferLineHistory(res.transferLineHistory);
        }, 3000);
        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    if (isLoading) {
        return (
            <div className="flex h-[100vh] w-[100vw] items-center justify-center">
                <Loader />
            </div>
        );
    }

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Transfer Line History</h1>
                    <p className="mt-2 text-sm text-gray-700">A list of all the transfer line history</p>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0">{/* <Search /> */}</div>
            </div>
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Time
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                                        >
                                            Hubspot ID
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                                        >
                                            Contact ID
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {transferLineHistory.map(line => (
                                        <tr key={line.id}>
                                            <td className="whitespace-nowrap px-3 py-4 text-left text-sm font-medium text-gray-900">
                                                {(() => {
                                                    const date = new Date(line.created);
                                                    const formattedDate = date.toLocaleString('en-US', {
                                                        month: 'short',
                                                        day: 'numeric',
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        hour12: true
                                                    });
                                                    const weekday = date.toLocaleString('en-US', { weekday: 'short' });
                                                    const withSuffix = formattedDate.replace(/(\d+)/, match => {
                                                        const day = parseInt(match);
                                                        const suffix = ['th', 'st', 'nd', 'rd'][day % 10] || 'th';
                                                        return `${day}${suffix}`;
                                                    });
                                                    return `${withSuffix}, ${weekday}`;
                                                })()}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-left text-sm text-gray-900">
                                                {line.firstName} {line.lastName}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-center text-sm font-bold text-gray-900">
                                                {line.hubspotContactId}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-center text-sm font-bold text-red-600">
                                                <Link
                                                    to={`/contacts/${line.contactId}`}
                                                    className="underline hover:text-red-800"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {line.contactId}
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Transfers;

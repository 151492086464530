import React, { useEffect, useState } from 'react';
import ContactDetail from './ContactDetail/ContactInfo';
import { Link, useParams } from 'react-router-dom';
import getContactDetail, { TypeContactDetail } from 'api/get-contact-detail';
import SpinLoader from 'components/ui/spinLoader';
import AiEnabledButton from './Contacts/AiEnabledButton';
import Hubspot from 'components/logos/hubspot';
import resetContact from 'api/resetContact';
import config from 'utils/config';
import NextSequenceScheduledAtSelect from './Contacts/nextSequenceScheduledAtSelect';
import { millionSecondsToDhmsString } from 'lib/seconds-to-days-hours-mins';

function Contact() {
    const { contactId } = useParams();

    const [contactInfo, setContactInfo] = useState<TypeContactDetail | null>(null);
    const [isloading, setIsLoading] = useState(false);

    let nextSequenceTimeFromNow = '';
    if (contactInfo) {
        if (contactInfo.generalInfo.nextSequenceScheduledAt) {
            nextSequenceTimeFromNow = millionSecondsToDhmsString(
                contactInfo.generalInfo.nextSequenceScheduledAt - Date.now()
            );
            if (nextSequenceTimeFromNow === '') {
                nextSequenceTimeFromNow = 'now';
            }
        }
    }

    useEffect(() => {
        const fetchContactDetail = async () => {
            if (!contactId) {
                return;
            }
            setIsLoading(true);
            const contactInfo = await getContactDetail({
                contactId: parseInt(contactId)
            });
            setContactInfo(contactInfo);
            setIsLoading(false);
        };
        fetchContactDetail();
    }, []);

    const handleReset = async () => {
        if (!contactInfo) return;
        await resetContact({ contactId: contactInfo.generalInfo.id });
        window.location.reload();
    };

    if (isloading || contactInfo === null) return <SpinLoader className="mr-2 h-4 w-4 animate-spin" />;

    if (!contactId) {
        return <div>No contact ID Found</div>;
    }

    return (
        <div>
            <div className="m-3 flex items-center gap-6">
                <div className="flex gap-2">
                    <Link
                        to={`${contactInfo.generalInfo.hubspotUrl}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-2 hover:underline"
                    >
                        <Hubspot className="h-4 w-4" /> {contactInfo.generalInfo.hubspotContactId}
                    </Link>
                </div>
                <p className="flex gap-2 hover:underline">{`(${contactInfo.generalInfo.id})`}</p>
                <div className="flex items-center gap-1 space-x-2">
                    <span>Intake Completion</span>
                    <span className="underline">
                        {(contactInfo.generalInfo.intakeCompletionScore * 100).toFixed(0)}%
                    </span>
                </div>

                <div className="flex items-center space-x-2">
                    <span>AI follow ups</span>
                    <AiEnabledButton
                        data={{
                            id: contactInfo.generalInfo.id,
                            aiEnabled: contactInfo.generalInfo.aiEnabled,
                            aiEnabledReplySmsCall: contactInfo.generalInfo.aiEnabledReplySmsCall
                        }}
                        fieldName="aiEnabled"
                    />
                    {!contactInfo.generalInfo.aiEnabled && (
                        <span className="text-blue-500">({contactInfo.generalInfo.aiDisabledReason})</span>
                    )}
                </div>
                <div className="flex items-center space-x-2">
                    <span>AI responses</span>
                    <AiEnabledButton
                        data={{
                            id: contactInfo.generalInfo.id,
                            aiEnabled: contactInfo.generalInfo.aiEnabled,
                            aiEnabledReplySmsCall: contactInfo.generalInfo.aiEnabledReplySmsCall
                        }}
                        fieldName="aiEnabledReplySmsCall"
                    />
                    {!contactInfo.generalInfo.aiEnabledReplySmsCall && (
                        <span className="text-blue-500">({contactInfo.generalInfo.aiDisabledReplySmsCallReason})</span>
                    )}
                </div>
                <div className="flex items-center space-x-2 rounded-lg border-2 px-2 shadow-sm">
                    <span className="">sequence:</span>
                    <span className="">{contactInfo.generalInfo.sequenceName || 'None'}</span>
                </div>
                <div className="flex flex-col items-center space-x-2 rounded-lg border-2 px-2 shadow-sm">
                    <div className="flex items-center gap-2">
                        <span className="">next sequence:</span>
                        <span className="">{contactInfo.generalInfo.nextSequenceName || 'None'}</span>
                        <span className="mr-2 font-semibold">at (utc):</span>
                        <NextSequenceScheduledAtSelect
                            data={{
                                id: contactInfo.generalInfo.id,
                                nextSequenceScheduledAt: contactInfo.generalInfo.nextSequenceScheduledAt
                            }}
                        />
                    </div>
                    <span className="ml-2">
                        {contactInfo.generalInfo.nextSequenceScheduledAt
                            ? nextSequenceTimeFromNow === 'now'
                                ? 'Now'
                                : `in ${nextSequenceTimeFromNow}`
                            : ''}
                    </span>
                </div>
                {config.ENV !== 'prod' && (
                    <button onClick={handleReset} className="rounded bg-red-500 px-2 py-1 text-white hover:bg-red-600">
                        Reset Contact
                    </button>
                )}
            </div>
            <div className="m-3 w-full">
                <ContactDetail contactId={contactId} contactInfo={contactInfo} />
            </div>
        </div>
    );
}

export default Contact;
